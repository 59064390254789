<template>
<v-app>
  <app-header
    :headerOption="headerOption"
    ref="appheader"
    style="height : 0px"
    hidden
  ></app-header>
  <div class="app-context">
    <v-app-bar
        absolute
        color="#fcb69f"
        dark
        shrink-on-scroll
        src="https://picsum.photos/1920/1080?random"
      >
        <template v-slot:img="{ props }">
          <v-img
            v-bind="props"
            gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
          ></v-img>
        </template>

        <div class="logo" style="margin : auto; width : 1000px">
          <v-row>
            <v-col cols="12" md="6" style="text-align : center;"
              ><img class="header_logo" src="@/assets/images/logo_default.svg" @click="goHome()" style="cursor : pointer"/></v-col>
            <v-col cols="12" md="6"
              ><h1 style="font-size : 32px"></h1></v-col
            >
          </v-row>
        </div>
        <v-spacer></v-spacer>
      </v-app-bar>
    <div class="context flex withSide" style="margin-top : 130px">
      <side-menu
        :sideSet="sideSet"
        @call-pop-in-side-password="callPopWithSidePassword"
        @call-pop-in-side-issue="callPopWithSideIssue"
        @draw-done="initAuthInfo"
        @click-list="listReaded"
        style="margin-top : 55px"
      ></side-menu>
      <div style="position: relative; width: 80%; left: 10%">
        <div
          style="width: 100%; text-align: center; font-size: 32px; margin: 10px"
        >
          <span>상품 정보</span>

          <image-viewer
            v-if="showImage"
            :src="this.photo"
            @close="showImage = false"
            style="z-index: 2"
            :imgList="product_item"
          />
        </div>
        <v-container>
          <div class="row">
            <div class="col-md-5 col-sm-5 col-xs-12">
              <div style="border : 2px soild #000">
                <!--<v-carousel style="top: 2%">
                  <v-carousel-item
                    :src="'https://www.drrefer.net/api/pic/' + product_item[this.code - 1].attchId"
                    @click="viewPhoto('https://www.drrefer.net/api/pic/' + product_item[code - 1].attchId)"
                    v-if="product_item[this.code-1].attchId !== undefined && product_item[this.code-1].attchId !== null"
                  >
                  </v-carousel-item>
                </v-carousel>-->
                <img
                  v-if="product_item[this.code-1] !== undefined && product_item[this.code-1] !== null"
                  :src="'https://www.drrefer.net/api/pic/' + product_item[this.code - 1].attchId"
                  @click="viewPhoto('https://www.drrefer.net/api/pic/' + product_item[code - 1].attchId)"
                  style="width : 100%; height : 100%"
                >
                </img>
              </div>
            </div>
            <div class="col-md-7 col-sm-7 col-xs-12">
              <v-breadcrumbs class="pb-0" :items="breadcrums"></v-breadcrumbs>
              <div style="height : 30px"/>
              <div class="pl-6">
                <p class="display-1 mb-0" v-if="product_item[this.code-1] !== undefined && product_item[this.code-1] !== null">
                  {{ product_item[this.code - 1].prodNm }}
                </p>
                <div style="height : 20px"/>
                <p class="subtitle-1 font-weight-thin" v-if="product_item[this.code-1] !== undefined && product_item[this.code-1] !== null" v-html="product_item[this.code - 1].prodDesc" style="line-height: 1.5rem !important">
                </p>
              </div>
              <div class="pl-6">
              <p class="title">결제방식</p>
              <v-radio-group v-model="row" row>
                <v-radio label="신용카드" value="card"></v-radio>
                <v-radio label="계좌이체" value="trans"></v-radio>
              </v-radio-group>
              <p class="title">쿠폰</p>
              <v-select 
                :items="items" 
                v-model="selectedItems" 
                item-text="cponNm" 
                item-value="cponId" 
                label="적용가능 쿠폰 확인" 
                @change="changeItems()" 
                style="width : 400px"
                return-object
                >
                </v-select>
              <div style="height : 20px"/>
              <div v-if="product_item[this.code-1] !== undefined && product_item[this.code-1] !== null">
                <v-row>
                <v-chip
                  class="ma-2"
                  color="red"
                  text-color="white"
                  v-if="(applyYn.prodCd === '' || applyYn.prodCd === undefined) && (orderYn === '' || orderYn === undefined) || this.applyYn.prodCd !== this.product_item[this.code-1].prodCd"
                >
                  신청서 작성 필요
                </v-chip>
                <v-chip
                  class="ma-2"
                  color="blue"
                  text-color="white"
                  v-else-if="(applyYn.prodCd !== '' || applyYn.prodCd !== undefined) && (orderYn === '' || orderYn === undefined) && this.applyYn.prodCd === this.product_item[this.code-1].prodCd"
                >
                  결제 가능
                </v-chip>
                <v-chip
                  class="ma-2"
                  color="red"
                  text-color="white"
                  v-else
                >
                  주문 진행 중
                </v-chip>
                <v-card-actions class="pa-0" style="margin-top : -5px" v-if="selectedItems.cponId === '0'">
                  <p class="headline font-weight-light pt-3">
                    결제 금액 :<span> {{ product_item[this.code - 1].price }}원</span>
                  </p>
                  <v-spacer></v-spacer>
                  <!--<v-rating
                    v-model="rating"
                    class=""
                    background-color="warning lighten-3"
                    color="warning"
                    dense
                  ></v-rating>
                  <span class="body-2 font-weight-thin"> 25 REVIEWS</span>-->
                </v-card-actions>
                <v-card-actions class="pa-0" style="margin-top : -5px" v-else>
                  <p class="headline font-weight-light pt-3">
                     결제 금액 : <del style="color : red; font-size : 20px"> {{ product_item[this.code - 1].price }}원</del><span> {{prodPrice}}원</span>
                  </p>
                  <v-spacer></v-spacer>
                </v-card-actions>
                </v-row>
              </div>
              
              <div style="height : 20px"/>
              <v-btn
                class="primary white--text"
                outlined
                tile
                dense
                @click="payment(row)"
                v-if="applyYn.prodCd !== 'non'"
                large
                ><v-icon style="margin-right: 15px;">mdi-cash-usd</v-icon>
                <span style="font-size : 20px; line-height : 10px; position : relative; left : -8px;">결제 하기</span>
              </v-btn>
              <v-btn
                class="primary white--text"
                outlined
                tile
                dense
                @click="moveDesignApply()"
                v-else
                large
                ><v-icon style="margin-right: 15px;">mdi-cash-usd</v-icon>
                <span style="font-size : 20px; line-height : 10px; position : relative; left : -8px;">신청서 작성</span>
              </v-btn>
              <!--<v-btn class="ml-4" outlined tile>ADD TO WISHLIST</v-btn>-->
              </div>
            </div>
          </div>
          <div class="row" v-if="product_item[this.code-1] !== undefined && product_item[this.code-1] !== null">
            <v-card-text class="pa-0 pt-4" tile outlined>
              <p class="subtitle-1 font-weight-light pt-3 text-center">
                다른 디자인 보기
              </p>
              <v-divider></v-divider>
              <div class="row text-center">
                <div class="col-md-3 col-sm-4 col-xs-12 text-center" />
                <div class="col-md-3 col-sm-4 col-xs-12 text-center">
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      class="mx-auto"
                      color="grey lighten-4"
                      max-width="600"
                    >
                      <v-img
                        class="white--text align-end"
                        :aspect-ratio="16 / 9"
                        height="300px"
                        :src="
                          code - 2 < 0
                            ? 'https://www.drrefer.net/api/pic/' + product_item[product_item.length - 1].attchId
                            : 'https://www.drrefer.net/api/pic/' + product_item[code - 2].attchId
                        "
                      >
                        <span
                          style="
                            position: relative;
                            left: -50%;
                            width: 100%;
                            color: #ffffff;
                            font-size: 24px;
                            top: 20px;
                          "
                        >
                          <!--<h2 style="font-size: 28px;">
                            {{
                              code - 2 < 0
                                ? product_item[product_item.length - 1].prodName
                                : product_item[code - 2].prodName
                            }}
                          </h2>-->
                        </span>
                        <v-expand-transition>
                          
                          <div
                            v-if="hover"
                            class="d-flex transition-fast-in-fast-out dark darken-2 v-card--reveal display-3 white--text"
                            style="
                              opacity: 0.7;
                              background : #000;
                              height : 300px;
                            "
                          >
                            <v-btn
                              :href="
                                code - 1 < 1
                                  ? '/v/payment/designDetail/' +
                                    product_item.length
                                  : '/v/payment/designDetail/' +
                                    parseInt(code-1)
                              "
                              class=""
                              outlined
                              style="
                                margin : auto;
                                color: #fff;
                                font-size: 18px;
                                font-wright: bold;
                                opacity: 1;
                              "
                            >
                              VIEW
                            </v-btn>
                          </div>
                        </v-expand-transition>
                      </v-img>
                      <v-card-text class="text--primary">
                        <div>
                          <span style="text-decoration: none; color: #1976d2;"
                            >{{code-2 < 0
                                ? product_item[product_item.length - 1].prodNm
                                : product_item[code - 2].prodNm}}</span
                          >
                        </div>
                        <div>{{code - 2 < 0
                                ? product_item[product_item.length - 1].price
                                : product_item[code - 2].price}}원</div>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </div>
                <div class="col-md-3 col-sm-4 col-xs-12 text-center">
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      class="mx-auto"
                      color="grey lighten-4"
                      max-width="600"
                    >
                      <v-img
                        class="white--text align-end"
                        :aspect-ratio="16 / 9"
                        height="300px"
                        :src="
                          product_item[code] !== undefined &&
                          product_item[code] !== null
                            ? 'https://www.drrefer.net/api/pic/' + product_item[code].attchId
                            : 'https://www.drrefer.net/api/pic/' + product_item[0].attchId
                        "
                      >
                        <span
                          style="
                            position: relative;
                            left: -50%;
                            width: 100%;
                            color: #ffffff;
                            font-size: 20px;
                            top: 20px;
                          "
                        >
                          <!--<h2 style="font-size: 28px;">
                            {{
                              product_item[code] !== undefined &&
                              product_item[code] !== null
                                ? product_item[code].prodNm
                                : product_item[0].prodNm
                            }}
                          </h2>-->
                        </span>
                        <v-expand-transition>
                          <div
                            v-if="hover"
                            class="d-flex transition-fast-in-fast-out dark darken-2 v-card--reveal display-3 white--text"
                            style="
                              opacity: 0.7;
                              background : #000;
                              height : 300px;
                            "
                          >
                            <v-btn
                              :href="
                                code + 1 > product_item.length
                                  ? '/v/payment/designDetail/1'
                                  : '/v/payment/designDetail/' +
                                    parseInt(code + 1)
                              "
                              class=""
                              outlined
                              style="
                                margin : auto;
                                color: #fff;
                                font-size: 18px;
                                font-wright: bold;
                                opacity: 1;
                              "
                              >VIEW</v-btn
                            >
                          </div>
                        </v-expand-transition>
                      </v-img>
                      <v-card-text class="text--primary">
                        <div>
                          <span style="text-decoration: none; color: #1976d2;">{{
                            product_item[code] !== undefined &&
                            product_item[code] !== null
                              ? product_item[code].prodNm
                              : product_item[0].prodNm
                          }}</span>
                        </div>
                        <div>{{
                            product_item[code] !== undefined &&
                            product_item[code] !== null
                              ? product_item[code].price
                              : product_item[0].price
                          }}원</div>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </div>
                <div class="col-md-3 col-sm-4 col-xs-12 text-center" />
              </div>
            </v-card-text>
          </div>
        </v-container>
        <div style="height: 100px" />
      </div>
    </div>
    <!-- <app-footer></app-footer> -->
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</v-app>
</template>

<script>
import AppHeader from "@/components/appHeader/AppHeaderTest";
import AppFooter from "@/components/AppFooter";
import ImageViewer from "@/components/ImageViewer";
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import SideMenu from "@/components/sidemenu/SideMenu";
import Vue from 'vue';
export default {
  components: { AppHeader, AppFooter, ImageViewer, DefaultPopup, SideMenu },
  created() {
    this.code = parseInt(this.$route.params.code);
    // console.log(this.code);
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  data() {
    return {
      prodPrice : '',
      applyYn: {
        aplyId : '',
        aplyDt : '',
        prgsStatCode : '',
        aplyDrId : '',
        prodCd : 'non',
      },
      orderYn : '',
      image: [],
      sideSet: {
        menuName: "payment",
        sideTitle: "소개 디자인",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
      popupSet: {},
      headerOption: {
        navOrder: 0,
        isMain: true,
      },
      showImage: false,
      code: 0,
      product_item: [
        {
          attchId: "",
        },
      ],
      photo: "",
      row: "",
      rating: 4.5,
      breadcrums: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Design List",
          disabled: false,
          href: "/v/payment/designList",
        },
        {
          text: "Design",
          disabled: true,
          href: "/v/payment/designDetail",
        },
      ],
      items: [],
      itemsList : [],
      selectedItems: {
        cponNm : "쿠폰 사용안함",
        cponId : "0"
      },
    };
  },
  mounted() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
    this.getProdList();
    document.querySelector(".designList").classList.add("active");
    // console.log(this.product_item[this.code - 1].attchId);
    // console.log('applyYn : ' + this.applyYn);
    // console.log('orderYn : ' + this.orderYn);
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    ...mapGetters("prod", ["GET_PROD_LIST", "GET_APPLY_YN","GET_ORDER_YN", "GET_COUPON_LIST"]),
    ...mapGetters("join", ["GET_BASIC", "GET_IDENTIFY"]),
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapActions("prod", ["BRING_PROD_LIST", "ORDER_SAVE", "ORDER_COMPLETE","ORDER_PROGRESS_FAIL", "BRING_CHECK_ORDER","BRING_CHECK_APPLY", "BRING_COUPON_LIST", "ORDER_COMPLETE_WITH_NO_COST"]),
    ...mapActions("join", [
      "FETCH_BASIC",
    ]),
    goHome() {
      this.$router.push("/").catch(()=>{});
    },
    changeItems(){
      this.prodPrice = this.product_item[this.code - 1].price;
      if(this.selectedItems.cponId !== '0')
        for(let i = 0; i < this.itemsList.length; i++)
        {
          if(this.itemsList[i].cponId === this.selectedItems.cponId)
          {
            // console.log(this.itemsList[i]);
            let num1 = Number(this.product_item[this.code - 1].price);
            let num2 = Number(this.itemsList[i].cponAmt);

            this.prodPrice = (num1+num2) +"";
            //console.log(this.product_item[this.code - 1].price);
          }
      }
    },
    async getCouponList(){
      // console.log("쿠폰 시작")
      // console.log(this.applyYn.prodCd);
      this.items = [];
      try{
        const couponParam = {
          prodDvcd : this.applyYn.prodCd[0],
          drId: localStorage.getItem("drId"),
        };  
        const transedApplyParam = JSON.stringify(couponParam);
        const transData = new FormData();
        transData.append("couponParam", transedApplyParam);
        this.items.push({cponNm : "쿠폰 사용안함", cponId : '0'}); // 쿠폰선택안함할때 
        await this.BRING_COUPON_LIST(transData).then(()=>{
          // console.log(this.GET_COUPON_LIST);
          let tempList = JSON.parse(JSON.stringify(this.GET_COUPON_LIST));
          this.itemsList = tempList;
          // console.log(this.itemsList);
          for(let i =0;i<this.GET_COUPON_LIST.length;i++){
            this.items.push({cponNm : this.GET_COUPON_LIST[i].cponNm + " " +this.GET_COUPON_LIST[i].cponAmt+"원", cponId : this.GET_COUPON_LIST[i].cponId});
          }
          // console.log(this.items);
        }); 
      }catch(error){
        console.log(error);
      }
    },
    async getMemberInfo() {
      try {
        await this.FETCH_BASIC();
      } catch (error) {
        console.log("error :", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "입력정보가 서버로 전송되지 못했습니다.";
          this.popupSet.popType = "custom";
          this.popupSet.cancelBtnText = undefined;
          this.popupSet.confirmBtnText = "확인";
          this.popupSet.nextLink = "/v/mypage/modifymember";
          this.popupSet.destination = true;
        }
      }
      /** 비밀번호는 팝업창을 통해서 가져오기 (sesstionStorage) */
    },
    async getProdList() {

      const searchOpt = {
        pageNo: 1,
        recordsPerPage: 10,
      };
      try {
        await this.BRING_PROD_LIST(searchOpt)
          .then(() => {
            //console.log(this.GET_PROD_LIST);

            var array = {};
            array = this.GET_PROD_LIST.list;

            for (var i = 0; i < array.length; i++) {
              array[i] = Object.assign({ id: i + 1 }, array[i]);
            }
            // console.log(array);

            this.product_item = array;
            //console.log(this.product_item[this.code - 1].attchId);
          })
          .then(() => {
            this.image[0] =
              "https://www.drrefer.net/api/pic/" +
              this.product_item[this.code - 1].attchId;
            if (this.code - 2 < 0) {
              this.image[1] =
                "https://www.drrefer.net/api/pic/" +
                this.product_item[this.product_item.length - 1].attchId;
            } else {
              this.image[1] =
                "https://www.drrefer.net/api/pic/" +
                this.product_item[this.code - 2].attchId;
            }
            
            if (
              this.product_item[this.code] !== undefined &&
              this.product_item[this.code] !== null
            ) {
              this.image[2] = 'https://www.drrefer.net/api/pic/' + this.product_item[this.code].attchId
            }
            else{
              this.image[2] = 'https://www.drrefer.net/api/pic/' + this.product_item[0].attchId
            }
            // console.log(this.image);
            
          }).then(async()=>{
              const applyParam = {
              drId: localStorage.getItem("drId"),
              };
              const transedApplyParam = JSON.stringify(applyParam);
              const transData = new FormData();
              transData.append("applyParam", transedApplyParam);
              await this.BRING_CHECK_ORDER(transData).then(()=>{
                if(this.GET_ORDER_YN){
                  this.orderYn = this.GET_ORDER_YN;
                }
                // console.log("오더값 : " + this.orderYn);
              }); 

              await this.BRING_CHECK_APPLY(transData).then(()=>{
                if(this.GET_APPLY_YN.prodCd){
                  this.applyYn = this.GET_APPLY_YN;
                }
                // console.log("어플라이 상품코드값 : " + this.applyYn.prodCd);
                this.getCouponList();
              }); 
          });
      } catch (error) {
        console.log("error :", error);
      }
    },
    async payment(row) {
      //console.log(this.applyYn.prodCd);
      //console.log(this.product_item[this.code-1].prodCd);

      if(this.applyYn.prodCd === this.product_item[this.code-1].prodCd){
        if(this.selectedItems.cponId === '0'){
          this.getMemberInfo();
           //console.log("가져온데이터", this.GET_BASIC);
           //console.log('결제 방식 : ' + row);
           //console.log('결제 디자인 : ');
           //console.log(this.product_item[this.code-1]);
           //console.log('결제 하는 id : ' + localStorage.getItem('drId'));
          if(this.GET_ORDER_YN == "" || this.GET_ORDER_YN == null) {
            const orderParam = {
              ordrPrice : this.product_item[this.code-1].price,
              prodCd : this.product_item[this.code-1].prodCd,
              aplyId : this.GET_APPLY_YN.aplyId,
            }
            // console.log(orderParam);
            const transedOrderParam = JSON.stringify(orderParam);
            const transData = new FormData();
            transData.append("orderParam", transedOrderParam);
            try{
              await this.ORDER_SAVE(transData).then((data)=>{
                console.log(data);
                  if (row !== "") {
                  /*const test = {
                    pg: 'danal_tpay',
                      pay_method: this.row,
                      merchant_uid: data.ordrId+"",
                      name: this.product_item[this.code-1].prodNm,
                      amount: this.product_item[this.code-1].price,
                      buyer_email: this.GET_BASIC.email,
                      buyer_name: this.GET_BASIC.drNm,
                      buyer_tel: this.GET_BASIC.hpNo,
                      buyer_addr:  this.GET_BASIC.addr,
                      buyer_postcode: this.GET_BASIC.postNo
                  }
                  console.log(test);*/
                  Vue.IMP().request_pay({
                      pg: 'danal_tpay',
                      pay_method: this.row,
                      merchant_uid: data.ordrId+"",
                      name: this.product_item[this.code-1].prodNm,
                      amount: this.product_item[this.code-1].price,
                      buyer_email: this.GET_BASIC.email,
                      buyer_name: this.GET_BASIC.drNm,
                      buyer_tel: this.GET_BASIC.hpNo,
                      buyer_addr:  this.GET_BASIC.addr,
                      buyer_postcode: this.GET_BASIC.postNo
                  }, async (result_success) => {
                      //성공할 때 실행 될 콜백 함수
                      /*var msg = '결제가 완료되었습니다.';
                      msg += '고유ID : ' + result_success.imp_uid;
                      msg += '상점 거래ID : ' + result_success.merchant_uid;
                      msg += '결제 금액 : ' + result_success.paid_amount;
                      msg += '카드 승인번호 : ' + result_success.apply_num;
                      alert(msg);*/
                      // console.log("result_success");
                      // console.log(result_success);
                      const PaymentParam ={
                        impUid : result_success.imp_uid,
                        orderId : result_success.merchant_uid,
                        amount : result_success.paid_amount,
                        drId : localStorage.getItem('drId'),
                      }
                        /*const transedOrderParam = JSON.stringify(PaymentParam);
                        const transData = new FormData();
                        transData.append("orderParam", transedOrderParam);*/
                      // console.log(PaymentParam);
                      await this.ORDER_COMPLETE(PaymentParam).then((data)=>{
                          if(data === null){
                            this.SET_POPUP(true);
                            this.popupSet.title = "상품 주문 실패";
                            this.popupSet.content = "상품 주문에 실패하였습니다";
                            this.popupSet.popType = "warn";
                            this.popupSet.cancelBtnText = "확인";
                            return;
                          }

                          this.SET_POPUP(true);
                          this.popupSet.title = "상품주문완료";
                          this.popupSet.content = "상품 주문에 성공하였습니다.";
                          this.popupSet.popType = "customForProd";
                          this.popupSet.confirmBtnText = "확인";
                          this.popupSet.destination = true;
                          this.popupSet.nextLink = "/v/payment/refund";
                        })
                  }, async (result_failure) => {
                      //실패시 실행 될 콜백 함수
                      //console.log("결제 취소");
                      //console.log(result_failure);
                      const RefundParam = {
                        drId : localStorage.getItem('drId'),
                        impUid : result_failure.imp_uid,
                        merchantUid : result_failure.merchant_uid
                      }
                      await this.ORDER_PROGRESS_FAIL(RefundParam).then(()=>{
                        var msg = '결제에 실패하였습니다.';
                        msg += '에러내용 : ' + result_failure.error_msg;
                        alert(msg);
                      })
                  })
                  } else {
                    this.SET_POPUP(true);
                    this.popupSet.title = "결제요청 에러";
                    this.popupSet.content = "결제 수단이 선택 되지 않았습니다.";
                    this.popupSet.popType = "warn";
                    this.popupSet.cancelBtnText = "확인";
                  }
              })
            }catch(err){
              console.log(err)
            }
          }
          else{
            this.SET_POPUP(true);
            this.popupSet.title = "디자인 주문 진행 중";
            this.popupSet.content = "주문 진행중인 소개 디자인이 존재합니다. \n 하단의 확인 버튼을 누르면 소개 디자인 구매 관리 탭으로 \n 이동합니다.";
            this.popupSet.popType = "customForProd";
            this.popupSet.confirmBtnText = "확인";
            this.popupSet.destination = true;
            this.popupSet.nextLink = "/v/payment/refund";
            this.popupSet.prodNo = this.product_item[this.code-1].prodNo;
          }
        } else{ // 쿠폰 사용
          this.getMemberInfo();
          // console.log("가져온데이터", this.GET_BASIC);
          // console.log('결제 방식 : ' + row);
          // console.log('결제 디자인 : ');
          // console.log(this.product_item[this.code-1]);
          // console.log('결제 하는 id : ' + localStorage.getItem('drId'));
          // console.log(this.prodPrice);
          if(this.prodPrice !== '0'){
            // console.log("공짜아님")
            
            if(this.GET_ORDER_YN == "" || this.GET_ORDER_YN == null) {
              const orderParam = {
                ordrPrice : this.prodPrice,
                prodCd : this.product_item[this.code-1].prodCd,
                aplyId : this.GET_APPLY_YN.aplyId,
              }
              // console.log(orderParam);
              const transedOrderParam = JSON.stringify(orderParam);
              const transData = new FormData();
              transData.append("orderParam", transedOrderParam);
              try{
                await this.ORDER_SAVE(transData).then((data)=>{
                  // console.log(data);
                    if (row !== "") {
                    Vue.IMP().request_pay({
                        pg: 'danal_tpay',
                        pay_method: this.row,
                        merchant_uid: data.ordrId+"",
                        name: this.product_item[this.code-1].prodNm,
                        amount: this.prodPrice,
                        buyer_email: this.GET_BASIC.email,
                        buyer_name: this.GET_BASIC.drNm,
                        buyer_tel: this.GET_BASIC.hpNo,
                        buyer_addr:  this.GET_BASIC.addr,
                        buyer_postcode: this.GET_BASIC.postNo
                    }, async (result_success) => {
                        //성공할 때 실행 될 콜백 함수
                        /*var msg = '결제가 완료되었습니다.';
                        msg += '고유ID : ' + result_success.imp_uid;
                        msg += '상점 거래ID : ' + result_success.merchant_uid;
                        msg += '결제 금액 : ' + result_success.paid_amount;
                        msg += '카드 승인번호 : ' + result_success.apply_num;
                        alert(msg);*/
                        // console.log("result_success");
                        // console.log(result_success);
                        const PaymentParam ={
                          impUid : result_success.imp_uid,
                          orderId : result_success.merchant_uid,
                          amount : result_success.paid_amount,
                          drId : localStorage.getItem('drId'),
                        }
                          /*const transedOrderParam = JSON.stringify(PaymentParam);
                          const transData = new FormData();
                          transData.append("orderParam", transedOrderParam);*/
                        // console.log(PaymentParam);
                        await this.ORDER_COMPLETE(PaymentParam).then((data)=>{
                            // console.log(data);
                            this.SET_POPUP(true);
                            this.popupSet.title = "상품주문완료";
                            this.popupSet.content = "상품 주문에 성공하였습니다.";
                            this.popupSet.popType = "customForProd";
                            this.popupSet.confirmBtnText = "확인";
                            this.popupSet.destination = true;
                            this.popupSet.nextLink = "/v/payment/refund";
                          })
                    }, async (result_failure) => {
                        //실패시 실행 될 콜백 함수
                        // console.log("결제 취소");
                        // console.log(result_failure);
                        const RefundParam = {
                          drId : localStorage.getItem('drId'),
                          impUid : result_failure.imp_uid,
                          merchantUid : result_failure.merchant_uid
                        }
                        await this.ORDER_PROGRESS_FAIL(RefundParam).then(()=>{
                          var msg = '결제에 실패하였습니다.';
                          msg += '에러내용 : ' + result_failure.error_msg;
                          alert(msg);
                        })
                    })
                    } else {
                      this.SET_POPUP(true);
                      this.popupSet.title = "결제요청 에러";
                      this.popupSet.content = "결제 수단이 선택 되지 않았습니다.";
                      this.popupSet.popType = "warn";
                      this.popupSet.cancelBtnText = "확인";
                    }
                })
              }catch(err){
                console.log(err)
              }
            }
            else{
              this.SET_POPUP(true);
              this.popupSet.title = "디자인 주문 진행 중";
              this.popupSet.content = "주문 진행중인 소개 디자인이 존재합니다. \n 하단의 확인 버튼을 누르면 소개 디자인 신청서 관리 탭으로 \n 이동합니다.";
              this.popupSet.popType = "customForProd";
              this.popupSet.confirmBtnText = "확인";
              this.popupSet.destination = true;
              this.popupSet.nextLink = "/v/payment/manageApply";
              this.popupSet.prodNo = this.product_item[this.code-1].prodNo;
            }
          }
          else{
            // console.log("공짜임")
            if(this.GET_ORDER_YN == "" || this.GET_ORDER_YN == null) {
              const orderParam = {
                ordrPrice : this.prodPrice,
                prodCd : this.product_item[this.code-1].prodCd,
                aplyId : this.GET_APPLY_YN.aplyId,
              }
              // console.log(orderParam);
              const transedOrderParam = JSON.stringify(orderParam);
              const transData = new FormData();
              transData.append("orderParam", transedOrderParam);
              try{
                await this.ORDER_SAVE(transData).then(async (data)=>{
                  // console.log(data);
                  //성공할 때 실행 될 콜백 함수
                  /*var msg = '결제가 완료되었습니다.';
                  msg += '고유ID : ' + result_success.imp_uid;
                  msg += '상점 거래ID : ' + result_success.merchant_uid;
                  msg += '결제 금액 : ' + result_success.paid_amount;
                  msg += '카드 승인번호 : ' + result_success.apply_num;
                  alert(msg);*/
                  const PaymentParam ={
                    orderId : data.ordrId,
                    amount : this.prodPrice,
                    drId : localStorage.getItem('drId'),
                    cponId : this.selectedItems.cponId,
                  }
                    /*const transedOrderParam = JSON.stringify(PaymentParam);
                    const transData = new FormData();
                    transData.append("orderParam", transedOrderParam);*/
                  // console.log(PaymentParam);
                  await this.ORDER_COMPLETE_WITH_NO_COST(PaymentParam).then((data)=>{
                      // console.log(data);
                      if(data === null){
                            this.SET_POPUP(true);
                            this.popupSet.title = "상품 주문 실패";
                            this.popupSet.content = "상품 주문에 실패하였습니다";
                            this.popupSet.popType = "warn";
                            this.popupSet.cancelBtnText = "확인";
                            return;
                          }
                      this.SET_POPUP(true);
                      this.popupSet.title = "상품주문완료";
                      this.popupSet.content = "상품 주문에 성공하였습니다.";
                      this.popupSet.popType = "customForProd";
                      this.popupSet.confirmBtnText = "확인";
                      this.popupSet.destination = true;
                      this.popupSet.nextLink = "/v/payment/refund";
                  })
                })
              }catch(err){
                console.log(err)
              }
            }
            else{
              this.SET_POPUP(true);
              this.popupSet.title = "디자인 주문 진행 중";
              this.popupSet.content = "주문 진행중인 소개 디자인이 존재합니다. \n 하단의 확인 버튼을 누르면 소개 디자인 신청서 관리 탭으로 \n 이동합니다.";
              this.popupSet.popType = "customForProd";
              this.popupSet.confirmBtnText = "확인";
              this.popupSet.destination = true;
              this.popupSet.nextLink = "/v/payment/manageApply";
              this.popupSet.prodNo = this.product_item[this.code-1].prodNo;
            }
          }
        }
      }  
      else{
        this.SET_POPUP(true);
        this.popupSet.title = "신청서 양식 오류";
        this.popupSet.content = "현재 작성하신 신청서와 주문요청한 상품종류가 맞지 않습니다.\n결제하실 상품종류를 확인해 주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      }
    },
    viewPhoto(src) {
      this.photo = src;
      // console.log(this.photo);
      this.showImage = true;
    },
    async callPopWithSidePassword() {
      let pop;
      pop = {
        popType: "CheckPasswordOwn",
        title: "비밀번호 확인",
        content: "현재 비밀번호를 입력해주세요.",
        immediatly: true,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
    async callPopWithSideIssue(subData) {
      // console.log("sub", subData);
      let pop;
      pop = {
        popType: "IssueChild",
        title: "부계정 관리",
        content:
          "발급할 부계정은 <b style='color:#ff0a00'>1" +
          localStorage.getItem("drId") +
          " </b>입니다.\n변경할 부계정의 비밀번호를 입력해주세요.",
        immediatly: true,
        functionValue: subData,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
    initAuthInfo() {
      // console.log("앱헤더에서 부를 것");
      this.$refs.appheader.calledNav("withDraw");
    },
    listReaded(order) {
      document.querySelector(".tabs__header").childNodes[order].click();
    },
    moveDesignApply(){
      this.SET_POPUP(true);
      this.popupSet.title = "신청서 작성 이동";
      this.popupSet.content = "하단의 확인 버튼을 누르면 소개 디자인 신청서 작성 페이지로\n 이동합니다.";
      this.popupSet.popType = "customForProd";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
      this.popupSet.nextLink = "/v/payment/apply";
      this.popupSet.prodCd = this.product_item[this.code-1].prodCd;
    }
  },
  destroyed() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "on",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
};
</script>

<style>
.pb-0 {
  position: relative;
  top: 10px;
}
</style>